import ResourceModelBase from 'odd-resource_model'
import TaiwanLoveCodes from '@services/taiwan_love_codes.json'
import AddressDataKeys from '@services/address_data_keys.json'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'user_common_addresses',
  attributes: [
    'id',
    'recipient',
    'dial_code',
    'phone',
    'company',
    'ein',
    'address'
  ],
  editableAttributes: [
    'recipient',
    'dial_code',
    'phone',
    'company',
    'ein',
    'address'
  ]
}

export default class UserCommonAddress extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
  fullAddress() {
    return [
      this.address.zip,
      this.address.city,
      this.address.area,
      this.address.street_address
    ]
      .filter(Boolean)
      .join(' ')
  }

  storeFullAddress() {
    let address = this.address.store_info.address

    return `${address.zip} ${address.city} ${address.area} ${address.street_address}`
  }

  fullPhone() {
    return ['oversea', 'oversea_billing'].includes(this.address.extra_data)
      ? `(${this.dial_code}) ${this.phone}`
      : this.phone
  }

  hasCompanyInfo() {
    return this.company && this.ein
  }

  isCvsStoreInfo() {
    return AddressDataKeys['cvs_data_keys'].includes(this.address.extra_data)
  }

  isStoreInfo() {
    return this.address.extra_data === 'store'
  }

  isOverseaLogistic() {
    return this.address.extra_data === 'oversea'
  }

  isNormalAddress() {
    return (
      !this.isCvsStoreInfo() && !this.isStoreInfo() && !this.isOverseaLogistic()
    )
  }

  get isBilling() {
    return this.address.extra_data === 'billing'
  }

  get invoiceType() {
    return this.address.invoice_type
  }

  get carrierNumber() {
    if (
      this.isBilling &&
      ['cell_phone', 'citizen_digital_no'].includes(this.invoiceType)
    ) {
      return this.address.carrier_number
    }
  }

  get loveCodeAndName() {
    let code = this.address.love_code

    return [code, TaiwanLoveCodes.find((info) => info.code === code)?.name]
      .filter(Boolean)
      .join(' ')
  }
}
